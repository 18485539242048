import { createContext, useContext, useId, useEffect, useMemo, ReactNode } from "react";

const Context = createContext<string[]>([]);

Context.displayName = "ClickOutsideContext";

const EVENT_BUBBLE_DELAY = 50;

const CLICK_OUTSIDE_TRACKER_ATTRIBUTE = "data-co-ids";

const formatTrackingAttribute = (ids: string[]) =>
  ids.length
    ? {
        [CLICK_OUTSIDE_TRACKER_ATTRIBUTE]: ids.join(","),
      }
    : {};

const useAncestorTrackingIds = () => useContext(Context);

/**
 * Returns an html attribute that should be added to portaled elements
 * so that when they are clicked they are not considered "outside" the current
 * ClickOutside context
 */
export const useClickOutsideTracker = () => {
  const ids = useAncestorTrackingIds();

  return useMemo(() => formatTrackingAttribute(ids), [ids]);
};

export type ClickOutsideProps = {
  handler?: (e: MouseEvent) => void;
  children: (props: { [CLICK_OUTSIDE_TRACKER_ATTRIBUTE]?: string }) => ReactNode;
};

/**
 * Wrap content with this component when you want to know when a click has occurred
 * outside of the content.
 * @param children a function that receives the props that should be added to tracked content.
 * @param handler The handler to call when the click outside happens. Wrap in useCallback!
 */
export const ClickOutside = ({ handler, children }: ClickOutsideProps) => {
  const trackingId = useId();

  // check to see if nested within other ClickOutsideListeners
  const ancestorTrackingIds = useAncestorTrackingIds();

  // combine all tracking ids
  const trackingIds = useMemo(() => {
    return [...ancestorTrackingIds, trackingId];
  }, [ancestorTrackingIds, trackingId]);
  const trackingProps = useMemo(() => formatTrackingAttribute(trackingIds), [trackingIds]);

  useEffect(() => {
    let timeout = 0;
    let clickHandler: (e: MouseEvent) => void;

    if (handler) {
      timeout = window.setTimeout(() => {
        // if click did not take place within any of the tracked elements for this particular
        // listener, a clickoutside took place
        clickHandler = (e: MouseEvent) => {
          const elements = [
            ...document.querySelectorAll<HTMLElement>(`[${CLICK_OUTSIDE_TRACKER_ATTRIBUTE}]`),
          ];
          const trackedElements = elements.filter((element) =>
            element.getAttribute(CLICK_OUTSIDE_TRACKER_ATTRIBUTE)?.split(",").includes(trackingId)
          );

          const isClickInside = trackedElements.some((trackedElement) =>
            trackedElement.contains(e.target as Node)
          );

          if (!isClickInside) {
            handler(e);
          }
        };
        document.addEventListener("mousedown", clickHandler);
      }, EVENT_BUBBLE_DELAY);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      document.removeEventListener("mousedown", clickHandler);
    };
  }, [handler, trackingId]);

  return <Context.Provider value={trackingIds}>{children(trackingProps)}</Context.Provider>;
};
