import {
  UpsertScheduleGuideRequest,
  ProviderVO,
  WorkingHourItemVO,
  AppointmentCategoryVO,
  RoomVO,
  ScheduleGuideCategorySettingsRequest,
} from "@libs/api/generated-api";
import { addEventColumns } from "@libs/api/scheduling/addEventColumns";
import { uniqueBy } from "@libs/utils/array";
import { keys } from "@libs/utils/object";
import { isDefined } from "@libs/utils/types";

type GroupedHourUnit = {
  hours: {
    startTime: string;
    endTime: string;
    daysOfWeek: ("MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY")[];
  };
  providers: ProviderVO[];
  rooms: RoomVO[];
  onlineBookableCategories: AppointmentCategoryVO[];
  categories: AppointmentCategoryVO[];
};

const getGuideStackSizes = (
  groupedHours: GroupedHourUnit[],
  allProviders: ProviderVO[],
  allRooms: RoomVO[]
) => {
  const daysToTest = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"] as const;

  let maxRoomGuidesColCount = 0;
  let totalRoomGuidesColCount = 0;
  let maxProviderGuidesColCount = 0;
  let totalProviderGuidesColCount = 0;
  let dayCount = 0;

  for (const day of daysToTest) {
    const dayGroupedHours = groupedHours.filter(({ hours }) => hours.daysOfWeek.includes(day));

    if (!dayGroupedHours.length) {
      continue;
    }

    dayCount++;

    const expandedRooms = dayGroupedHours
      .flatMap(({ rooms, hours }) => {
        return rooms.map((room) => ({
          resource: room.id,
          start: hours.startTime,
          end: hours.endTime,
        }));
      })
      .filter(isDefined);

    const expandedProviders = dayGroupedHours
      .flatMap(({ providers, hours }) => {
        return providers.map((provider) => ({
          resource: provider.id,
          start: hours.startTime,
          end: hours.endTime,
        }));
      })
      .filter(isDefined);
    const roomColumns = addEventColumns(
      expandedRooms,
      allRooms.map(({ id }) => id)
    );
    const providerColumns = addEventColumns(
      expandedProviders,
      allProviders.map(({ id }) => id)
    );

    const currentDay = {
      rooms: roomColumns.colCountPerResource,
      providers: providerColumns.colCountPerResource,
      maxProviderGuides: Math.max(...Object.values(providerColumns.colCountPerResource).filter(isDefined)),
      maxRoomGuides: Math.max(...Object.values(roomColumns.colCountPerResource).filter(isDefined)),
    };

    maxRoomGuidesColCount = Math.max(maxRoomGuidesColCount, currentDay.maxRoomGuides);
    maxProviderGuidesColCount = Math.max(maxProviderGuidesColCount, currentDay.maxProviderGuides);
    totalRoomGuidesColCount += currentDay.maxRoomGuides;
    totalProviderGuidesColCount += currentDay.maxProviderGuides;
  }

  return {
    avgMaxRoomColCount: dayCount > 0 ? totalRoomGuidesColCount / dayCount : 0,
    avgMaxProviderColCount: dayCount > 0 ? totalProviderGuidesColCount / dayCount : 0,
    maxProviderGuidesColCount,
    maxRoomGuidesColCount,
  };
};

const arraysEqual = (a: unknown[], b: unknown[]) => {
  return a.length === b.length && a.every((value, index) => value === b[index]);
};

const sortResources = <T extends { id: number }>(array: T[]) => {
  return [...array].sort((a, b) => a.id - b.id);
};

const keepUniquesAndSort = <T extends { id: number }>(array: T[], newItem: T[]) => {
  const merged = uniqueBy([...array, ...newItem], ({ id }) => id);

  return sortResources(merged);
};

const getSelfBookingConfigKey = (config: NonNullable<AppointmentCategoryVO["selfBookingConfig"]>) => {
  return `${config.bookingType}-${config.leadTimeToBookInDays}-${config.patientType}` as const;
};

const groupCategoriesByConfig = (categories: AppointmentCategoryVO[]) => {
  const grouped = categories.reduce(
    (acc, category) => {
      if (category.selfBookingConfig) {
        const key = getSelfBookingConfigKey(category.selfBookingConfig);

        acc[key] = acc[key] ? sortResources([...acc[key], category]) : [category];
      }

      return acc;
    },
    {} as Record<string, AppointmentCategoryVO[]>
  );

  return grouped;
};

const createProviderHours = (
  provider: ProviderVO,
  providerHoursByProvider: Record<number, WorkingHourItemVO[] | undefined>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[]
) => {
  const providerHours = providerHoursByProvider[provider.id] || [];
  const providerRooms = rooms.filter((room) => room.providers?.some((p) => p.id === provider.id));
  const workingHours = providerHours.length ? providerHours : practiceHours;
  const unAssignedRooms = rooms.filter((room) => !room.providers?.length);
  const usedRooms = providerRooms.length ? providerRooms : unAssignedRooms;

  if (usedRooms.length === 0) {
    // Provider has no rooms

    return [];
  }

  const providerCategories = categories.filter(
    (category) => category.providers.some((p) => p.id === provider.id) || !category.providers.length
  );

  const selfBookableCategories = providerCategories.filter((category) =>
    Boolean(category.isSelfBookable && category.selfBookingConfig)
  );
  const nonOnlineBookableCategories = providerCategories.filter(
    (category) => !category.isSelfBookable || !category.selfBookingConfig
  );

  const groupedHours: GroupedHourUnit[] = [];

  for (const workingHourItem of workingHours) {
    const groupedHoursItem: GroupedHourUnit = {
      hours: {
        startTime: workingHourItem.startTime,
        endTime: workingHourItem.endTime,
        daysOfWeek: [workingHourItem.dayOfWeek],
      },
      rooms: sortResources(usedRooms),
      providers: [provider],
      onlineBookableCategories: [],
      categories: [],
    };

    if (workingHourItem.isSelfBookable && selfBookableCategories.length) {
      groupedHours.push({
        ...groupedHoursItem,
        onlineBookableCategories: sortResources(selfBookableCategories),
      });

      if (nonOnlineBookableCategories.length) {
        groupedHours.push({
          ...groupedHoursItem,
          categories: sortResources(nonOnlineBookableCategories),
        });
      }
    } else if (providerCategories.length) {
      groupedHours.push({
        ...groupedHoursItem,
        categories: sortResources(providerCategories),
      });
    } else {
      groupedHours.push(groupedHoursItem);
    }
  }

  return groupedHours;
};

const createRoomHours = (
  room: RoomVO,
  rooms: RoomVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[] | undefined>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  providers: ProviderVO[]
) => {
  const unAssignedProviders = providers.filter((provider) =>
    rooms.every((r) => !r.providers?.some((p) => p.id === provider.id))
  );
  const roomProviders = room.providers?.length ? room.providers : unAssignedProviders;

  const categoriesByProvider: Record<
    number,
    {
      all: AppointmentCategoryVO[];
      selfBookable: AppointmentCategoryVO[];
      nonSelfBookable: AppointmentCategoryVO[];
    }
  > = {};

  for (const provider of roomProviders) {
    const providerCategories = categories.filter(
      (category) => category.providers.some((p) => p.id === provider.id) || !category.providers.length
    );

    categoriesByProvider[provider.id] = {
      all: providerCategories,
      nonSelfBookable: providerCategories.filter(
        (category) => !category.isSelfBookable || !category.selfBookingConfig
      ),
      selfBookable: providerCategories.filter((category) =>
        Boolean(category.isSelfBookable && category.selfBookingConfig)
      ),
    };
  }

  const workingHours: (WorkingHourItemVO & {
    provider: ProviderVO;
    selfBookableCategories?: AppointmentCategoryVO[];
    nonSelfBookableCategories?: AppointmentCategoryVO[];
    categories?: AppointmentCategoryVO[];
  })[] = roomProviders.flatMap((provider) => {
    const providerHours = providerHoursByProvider[provider.id] || [];
    const providerCategories = categoriesByProvider[provider.id];

    let hours = practiceHours;

    if (providerHours.length) {
      hours = providerHours.map((hoursItem) => {
        if (hoursItem.isSelfBookable && providerCategories?.selfBookable.length) {
          return {
            ...hoursItem,
            nonSelfBookableCategories: providerCategories.nonSelfBookable,
            selfBookableCategories: providerCategories.selfBookable,
          };
        }

        if (providerCategories?.all.length) {
          return {
            ...hoursItem,
            categories: providerCategories.all,
          };
        }

        return hoursItem;
      });
    }

    return hours.map((hoursItem) => ({
      ...hoursItem,
      provider,
    }));
  });

  const groupedHours: GroupedHourUnit[] = [];

  for (const workingHourItem of workingHours) {
    const groupedHoursItem: GroupedHourUnit = {
      hours: {
        startTime: workingHourItem.startTime,
        endTime: workingHourItem.endTime,
        daysOfWeek: [workingHourItem.dayOfWeek],
      },
      rooms: [room],
      providers: [workingHourItem.provider],
      categories: [],
      onlineBookableCategories: [],
    };

    if (workingHourItem.isSelfBookable && workingHourItem.selfBookableCategories) {
      groupedHours.push({
        ...groupedHoursItem,
        onlineBookableCategories: sortResources(workingHourItem.selfBookableCategories),
      });

      if (workingHourItem.nonSelfBookableCategories?.length) {
        groupedHours.push({
          ...groupedHoursItem,
          categories: sortResources(workingHourItem.nonSelfBookableCategories),
        });
      }
    } else if (workingHourItem.categories) {
      groupedHours.push({
        ...groupedHoursItem,
        categories: sortResources(workingHourItem.categories),
      });
    } else {
      groupedHours.push(groupedHoursItem);
    }
  }

  return combineProviderHours(groupedHours);
};

const combineProviderHours = (groupedHours: GroupedHourUnit[]) => {
  const consolidated: GroupedHourUnit[] = [];

  for (const current of groupedHours) {
    const existingGuideIndex = consolidated.findIndex((existing) => {
      return (
        existing.hours.startTime === current.hours.startTime &&
        existing.hours.endTime === current.hours.endTime &&
        arraysEqual(existing.hours.daysOfWeek, current.hours.daysOfWeek) &&
        arraysEqual(
          existing.rooms.map((room) => room.id),
          current.rooms.map((room) => room.id)
        ) &&
        arraysEqual(
          existing.categories.map((cat) => cat.id),
          current.categories.map((cat) => cat.id)
        ) &&
        arraysEqual(
          existing.onlineBookableCategories.map((cat) => cat.id),
          current.onlineBookableCategories.map((cat) => cat.id)
        )
      );
    });

    const existingGuide = existingGuideIndex === -1 ? undefined : consolidated[existingGuideIndex];

    if (existingGuide) {
      consolidated[existingGuideIndex] = {
        ...existingGuide,
        providers: keepUniquesAndSort(existingGuide.providers, current.providers),
      };
    } else {
      consolidated.push(current);
    }
  }

  return consolidated;
};

const combineRoomHours = (groupedHours: GroupedHourUnit[]) => {
  const consolidated: GroupedHourUnit[] = [];

  for (const current of groupedHours) {
    const existingGuideIndex = consolidated.findIndex((existing) => {
      return (
        existing.hours.startTime === current.hours.startTime &&
        existing.hours.endTime === current.hours.endTime &&
        arraysEqual(existing.hours.daysOfWeek, current.hours.daysOfWeek) &&
        arraysEqual(
          existing.providers.map((provider) => provider.id),
          current.providers.map((provider) => provider.id)
        ) &&
        arraysEqual(
          existing.categories.map((cat) => cat.id),
          current.categories.map((cat) => cat.id)
        ) &&
        arraysEqual(
          existing.onlineBookableCategories.map((cat) => cat.id),
          current.onlineBookableCategories.map((cat) => cat.id)
        )
      );
    });

    const existingGuide = existingGuideIndex === -1 ? undefined : consolidated[existingGuideIndex];

    if (existingGuide) {
      consolidated[existingGuideIndex] = {
        ...existingGuide,
        rooms: keepUniquesAndSort(existingGuide.rooms, current.rooms),
      };
    } else {
      consolidated.push(current);
    }
  }

  return consolidated;
};

const combineCategories = (groupedHours: GroupedHourUnit[]) => {
  const consolidated: GroupedHourUnit[] = [];

  for (const current of groupedHours) {
    const existingGuideIndex = consolidated.findIndex((existing) => {
      return (
        existing.hours.startTime === current.hours.startTime &&
        existing.hours.endTime === current.hours.endTime &&
        arraysEqual(existing.hours.daysOfWeek, current.hours.daysOfWeek) &&
        arraysEqual(
          existing.providers.map((provider) => provider.id),
          current.providers.map((provider) => provider.id)
        ) &&
        arraysEqual(
          existing.rooms.map((room) => room.id),
          current.rooms.map((room) => room.id)
        )
      );
    });

    const existingGuide = existingGuideIndex === -1 ? undefined : consolidated[existingGuideIndex];

    if (existingGuide) {
      consolidated[existingGuideIndex] = {
        ...existingGuide,
        categories: keepUniquesAndSort(existingGuide.categories, current.categories),
        onlineBookableCategories: keepUniquesAndSort(
          existingGuide.onlineBookableCategories,
          current.onlineBookableCategories
        ),
      };
    } else {
      consolidated.push(current);
    }
  }

  return consolidated;
};

const combineDays = (groupedHours: GroupedHourUnit[]) => {
  const consolidated: GroupedHourUnit[] = [];

  for (const current of groupedHours) {
    const existingGuideIndex = consolidated.findIndex((existing) => {
      return (
        existing.hours.startTime === current.hours.startTime &&
        existing.hours.endTime === current.hours.endTime &&
        arraysEqual(
          existing.rooms.map((room) => room.id),
          current.rooms.map((room) => room.id)
        ) &&
        arraysEqual(
          existing.providers.map((provider) => provider.id),
          current.providers.map((provider) => provider.id)
        ) &&
        arraysEqual(
          existing.categories.map((cat) => cat.id),
          current.categories.map((cat) => cat.id)
        ) &&
        arraysEqual(
          existing.onlineBookableCategories.map((cat) => cat.id),
          current.onlineBookableCategories.map((cat) => cat.id)
        )
      );
    });

    const existingGuide = existingGuideIndex === -1 ? undefined : consolidated[existingGuideIndex];

    if (existingGuide) {
      consolidated[existingGuideIndex] = {
        ...existingGuide,
        hours: {
          ...existingGuide.hours,
          daysOfWeek: [...new Set([...existingGuide.hours.daysOfWeek, ...current.hours.daysOfWeek])].sort(),
        },
      };
    } else {
      consolidated.push(current);
    }
  }

  return consolidated;
};

const createGuide = (groupedHours: GroupedHourUnit) => {
  let scheduleGuide: UpsertScheduleGuideRequest = {
    title: "Schedule Guide",
    date: "2025-01-01",
    startTime: groupedHours.hours.startTime,
    endTime: groupedHours.hours.endTime,
    roomIds: groupedHours.rooms.map((room) => room.id),
    providerIds: groupedHours.providers.map((provider) => provider.id),
    recurrence: {
      daysOfWeek: [...groupedHours.hours.daysOfWeek],
      every: 1,
      recurrenceType: "DAYS_OF_WEEKS",
    },
    scheduleGuideCategorySettings: [],
  };

  if (groupedHours.categories.length) {
    scheduleGuide = {
      ...scheduleGuide,
      scheduleGuideCategorySettings: [
        {
          categoryIds: groupedHours.categories.map((category) => category.id),
          treatmentPlan: true,
          canMultiBook: false,
          isSelfBookable: false,
        },
      ],
    };
  }

  if (groupedHours.onlineBookableCategories.length) {
    const groupedCategories = groupCategoriesByConfig(groupedHours.onlineBookableCategories);
    const categorySettings = Object.values(groupedCategories).map(
      (categories): ScheduleGuideCategorySettingsRequest => {
        const selfBookingConfig = categories[0]?.selfBookingConfig;

        if (!selfBookingConfig) {
          throw new Error("Expected self booking config");
        }

        return {
          categoryIds: categories.map((category) => category.id),
          treatmentPlan: true,
          canMultiBook: false,
          isSelfBookable: true,
          selfBookingConfig,
        };
      }
    );

    scheduleGuide = {
      ...scheduleGuide,
      scheduleGuideCategorySettings: [...scheduleGuide.scheduleGuideCategorySettings, ...categorySettings],
    };
  }

  if (!scheduleGuide.scheduleGuideCategorySettings.length) {
    scheduleGuide.scheduleGuideCategorySettings = [
      {
        categoryIds: [],
        treatmentPlan: true,
        canMultiBook: false,
        isSelfBookable: false,
      },
    ];
  }

  return scheduleGuide;
};

const getMergeStrategyAnalytics = (
  groupedHours: GroupedHourUnit[],
  providers: ProviderVO[],
  rooms: RoomVO[]
) => {
  const providersWithNoGuides = providers.filter(
    (provider) => !groupedHours.some((g) => g.providers.some((p) => p.id === provider.id))
  ).length;

  return {
    totalGuides: groupedHours.length,
    providersWithNoGuides,
    ...getGuideStackSizes(groupedHours, providers, rooms),
  };
};

const getPracticeAnalytics = (
  practiceId: number,
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[] | undefined>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[]
) => {
  return {
    practiceId,
    roomCount: rooms.length,
    providerCount: providers.length,
    categoryCount: categories.length,
    onlineBookableCategoryCount: categories.filter((c) => c.isSelfBookable).length,
    hasPracticeHours: Boolean(practiceHours.length),
    hasSomeProviderHours: providers.some((provider) => providerHoursByProvider[provider.id]?.length),
    hasSomeOnlineBookableProviderHours: providers.some((provider) =>
      providerHoursByProvider[provider.id]?.some((h) => h.isSelfBookable)
    ),
    hasUnassignedRooms: rooms.some((room) => !room.providers?.length),
    pendingAssignedProviders: providers.filter(
      (provider) =>
        (provider.status === "PENDING" &&
          (providerHoursByProvider[provider.id]?.length ||
            rooms.some((room) => room.providers?.some((p) => p.id === provider.id)))) ||
        categories.some((category) => category.providers.some((p) => p.id === provider.id))
    ).length,
  };
};

const combineMap = {
  C: combineCategories,
  D: combineDays,
  R: (by: "provider" | "room") => (by === "room" ? combineRoomHours : combineProviderHours),
};
const combineOrders = ["CDR", "CRD", "DCR", "DRC", "RCD", "RDC"] as const;
const byOrders = ["provider", "room"] as const;

export const mergeStrategies = byOrders.flatMap((by) =>
  combineOrders.map((combine) => ({
    by,
    combine,
  }))
);

export type MergeStrategy = {
  by: ListItem<typeof byOrders>;
  combine: ListItem<typeof combineOrders>;
};

const getMergeStrategyKey = (mergeStrategy: MergeStrategy) => {
  return `${mergeStrategy.by}-${mergeStrategy.combine}` as const;
};

const findBestMergeStrategy = (
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[]
) => {
  const winners = {
    minAvgRoomStackCount: {
      value: Number.MAX_SAFE_INTEGER,
      winners: [] as string[],
    },
    minAvgProviderStackCount: {
      value: Number.MAX_SAFE_INTEGER,
      winners: [] as string[],
    },
    minGuides: {
      value: Number.MAX_SAFE_INTEGER,
      winners: [] as string[],
    },
  };

  const results: ReturnType<typeof executeMergeStrategy>[] = [];

  for (const mergeStrategy of mergeStrategies) {
    const result = executeMergeStrategy(
      providers,
      providerHoursByProvider,
      practiceHours,
      categories,
      rooms,
      mergeStrategy
    );

    const totalGuides = result.analytics.totalGuides;

    if (result.analytics.avgMaxRoomColCount < winners.minAvgRoomStackCount.value) {
      winners.minAvgRoomStackCount.winners = [result.strategyKey];
      winners.minAvgRoomStackCount.value = result.analytics.avgMaxRoomColCount;
    } else if (result.analytics.avgMaxRoomColCount === winners.minAvgRoomStackCount.value) {
      winners.minAvgRoomStackCount.winners.push(result.strategyKey);
    }

    if (result.analytics.avgMaxProviderColCount < winners.minAvgProviderStackCount.value) {
      winners.minAvgProviderStackCount.winners = [result.strategyKey];
      winners.minAvgProviderStackCount.value = result.analytics.avgMaxProviderColCount;
    } else if (result.analytics.avgMaxProviderColCount === winners.minAvgProviderStackCount.value) {
      winners.minAvgProviderStackCount.winners.push(result.strategyKey);
    }

    if (totalGuides < winners.minGuides.value) {
      winners.minGuides.winners = [result.strategyKey];
      winners.minGuides.value = totalGuides;
    } else if (totalGuides === winners.minGuides.value) {
      winners.minGuides.winners.push(result.strategyKey);
    }

    results.push(result);
  }

  const sortedResults = [...results].sort((a, b) => {
    let diff = a.analytics.avgMaxRoomColCount - b.analytics.avgMaxRoomColCount;

    if (diff !== 0) {
      return diff;
    }

    diff = a.analytics.avgMaxProviderColCount - b.analytics.avgMaxProviderColCount;

    if (diff !== 0) {
      return diff;
    }

    return a.analytics.totalGuides - b.analytics.totalGuides;
  });
  const [winner, ...rest] = sortedResults;
  const ties = rest
    .filter(
      (result) =>
        result.analytics.avgMaxRoomColCount === winner?.analytics.avgMaxRoomColCount &&
        result.analytics.avgMaxProviderColCount === winner.analytics.avgMaxProviderColCount &&
        result.analytics.totalGuides === winner.analytics.totalGuides
    )
    .map((result) => result.strategyKey);

  return {
    winningStrategies: winner ? [winner.strategyKey, ...ties] : ties,
    guides: winner?.guides ?? [],
    winnersByMetric: keys(winners).reduce(
      (next, key) => {
        next[key] = winners[key].winners;

        return next;
      },
      {} as Record<string, string[]>
    ),
    analytics: results.reduce(
      (next, { strategyKey, analytics }) => {
        next[strategyKey] = analytics;

        return next;
      },
      {} as Record<string, ReturnType<typeof getMergeStrategyAnalytics>>
    ),
  };
};

// const debugHours = (hours: GroupedHourUnit[]) => {
//   const simplieHours = hours.map((hour) => {
//     return {
//       ...hour,
//       rooms: hour.rooms.map((room) => room.id),
//       providers: hour.providers.map((provider) => provider.id),
//       onlineBookableCategories: hour.onlineBookableCategories.map((category) => category.id),
//       categories: hour.categories.map((category) => category.id),
//     };
//   });

//   console.log(JSON.stringify(simplieHours, null, 2));
// };

const createHours = (
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[],
  by: MergeStrategy["by"]
) =>
  by === "provider"
    ? providers.flatMap((provider) =>
        createProviderHours(provider, providerHoursByProvider, practiceHours, categories, rooms)
      )
    : rooms.flatMap((room) =>
        createRoomHours(room, rooms, providerHoursByProvider, practiceHours, categories, providers)
      );

const executeMergeStrategy = (
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[],
  mergeStrategy: MergeStrategy
) => {
  const strategyKey = getMergeStrategyKey(mergeStrategy);
  let hours = createHours(
    providers,
    providerHoursByProvider,
    practiceHours,
    categories,
    rooms,
    mergeStrategy.by
  );
  const combineFunctions = [...mergeStrategy.combine].map((combine) =>
    combine === "R" ? combineMap[combine](mergeStrategy.by) : combineMap[combine as "C" | "D"]
  );

  hours = combineFunctions.reduce((acc, fn) => {
    const next = fn(acc);

    return next;
  }, hours);

  return {
    strategyKey,
    analytics: getMergeStrategyAnalytics(hours, providers, rooms),
    guides: hours.map(createGuide),
  };
};

export const cleanData = (
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[]
) => {
  const activeProviders = providers.filter((provider) => {
    if (provider.status === "ACTIVE") {
      return true;
    }

    if (provider.status === "PENDING") {
      const hasHours = providerHoursByProvider[provider.id]?.length;
      const hasRoomAssignment = rooms.some((room) => room.providers?.some((p) => p.id === provider.id));
      const hasCategoryAssignment = categories.some((category) =>
        category.providers.some((p) => p.id === provider.id)
      );

      return hasHours || hasRoomAssignment || hasCategoryAssignment;
    }

    return false;
  });

  const cleanedRooms = rooms.map((room) => ({
    ...room,
    providers: room.providers?.filter((provider) => activeProviders.some((p) => p.id === provider.id)),
  }));

  const cleanedCategories = categories.map((cat) => ({
    ...cat,
    providers: cat.providers.filter((provider) => activeProviders.some((p) => p.id === provider.id)),
  }));

  // since practice hours marked as self bookable are not self bookable
  // let's ignore them for the migration
  const cleanedPracticeHours = practiceHours.map((hour) => ({
    ...hour,
    isSelfBookable: false,
  }));

  return {
    rooms: cleanedRooms,
    categories: cleanedCategories,
    practiceHours: cleanedPracticeHours,
    providers: activeProviders,
  };
};

export const createGuides = (
  practiceId: number,
  providers: ProviderVO[],
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>,
  practiceHours: WorkingHourItemVO[],
  categories: AppointmentCategoryVO[],
  rooms: RoomVO[],
  mergeStrategy: "best" | MergeStrategy
) => {
  const cleaned = cleanData(providers, providerHoursByProvider, practiceHours, categories, rooms);

  const practiceAnalytics = getPracticeAnalytics(
    practiceId,
    cleaned.providers,
    providerHoursByProvider,
    cleaned.practiceHours,
    cleaned.categories,
    cleaned.rooms
  );

  if (mergeStrategy === "best") {
    const { guides, analytics, winningStrategies } = findBestMergeStrategy(
      cleaned.providers,
      providerHoursByProvider,
      cleaned.practiceHours,
      cleaned.categories,
      cleaned.rooms
    );

    return {
      strategyKey: winningStrategies[0],
      guides,
      analytics: {
        ...practiceAnalytics,
        mergeStrategies: analytics,
      },
    };
  }

  const { guides, analytics, strategyKey } = executeMergeStrategy(
    cleaned.providers,
    providerHoursByProvider,
    cleaned.practiceHours,
    cleaned.categories,
    cleaned.rooms,
    mergeStrategy
  );

  return {
    strategyKey: getMergeStrategyKey(mergeStrategy),
    guides,
    analytics: {
      ...practiceAnalytics,
      mergeStrategies: {
        [strategyKey]: analytics,
      },
    },
  };
};
