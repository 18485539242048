import { forwardRef, InputHTMLAttributes, ReactNode, useId } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { isNumber } from "@libs/utils/types";
import { FloatingTooltip, FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";

export type FormatReadOnlyValue = (value: string | undefined | null, id: string) => ReactNode;

interface BaseProps {
  Icon?: IconComponent;
  iconOnClick?: Func;
  iconTooltip?: Omit<FloatingTooltipProps, "children">;
  displayErrorMessage?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
  readOnlyClassName?: string;
  formatReadOnlyValue?: FormatReadOnlyValue;
  value?: string | undefined | null;
  iconClassName?: string;
}

export type FormFieldInputProps = BaseProps &
  FormFieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, keyof FormFieldProps | "value">;

export const FormFieldInput = forwardRef<HTMLInputElement, FormFieldInputProps>(
  (
    {
      disabled,
      required,
      label,
      description,
      error,
      Icon,
      iconOnClick,
      iconTooltip = { content: "" },
      className,
      children,
      type = "text",
      id,
      edit = true,
      displayErrorMessage = true,
      inputClassName,
      iconClassName,
      inputWrapperClassName,
      formatReadOnlyValue,
      containerClassName,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const errorId = useId();

    const controlStyles = cxFormFieldStyle.control({
      hasIcon: Boolean(Icon),
    });
    const value = rest.value;
    const isValueBound = "value" in rest;

    return (
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        description={description}
        error={error}
        className={className}
        edit={edit}
        containerClassName={containerClassName}
        displayErrorMessage={displayErrorMessage}
        errorId={errorId}
        id={fieldId}
      >
        {edit ? (
          <div className={cx(cxFormFieldStyle.wrapper, inputWrapperClassName)}>
            <input
              id={fieldId}
              ref={ref}
              type={type}
              disabled={disabled}
              aria-invalid={error ? "true" : undefined}
              aria-errormessage={error ? errorId : undefined}
              className={cx(controlStyles, cxFormFieldStyle.input, inputClassName)}
              {...rest}
              value={isValueBound ? (value ?? "") : undefined}
            />
            {Icon ? (
              iconOnClick ? (
                <ButtonIcon
                  className={cxFormFieldStyle.iconContainer({
                    clickable: true,
                  })}
                  tooltip={iconTooltip}
                  SvgIcon={Icon}
                  onClick={iconOnClick}
                  iconClassName={iconClassName}
                />
              ) : (
                <div
                  className={cxFormFieldStyle.iconContainer({
                    clickable: Boolean(iconTooltip.content),
                  })}
                >
                  <FloatingTooltip {...iconTooltip}>
                    <Icon role="img" className={cx(cxFormFieldStyle.icon({ disabled }), iconClassName)} />
                  </FloatingTooltip>
                </div>
              )
            ) : null}
            {children}
          </div>
        ) : (
          <span aria-labelledby={fieldId} className={cxFormFieldStyle.controlValueOnly}>
            {formatReadOnlyValue
              ? formatReadOnlyValue(value, fieldId)
              : isNumber(value) || ![undefined, null, ""].includes(value)
                ? value
                : "-"}
          </span>
        )}
      </FormField>
    );
  }
);
