type GroupedArray<T extends Record<K, PropertyKey>, K extends keyof T> = {
  [P in T[K]]?: T[];
};

/**
 * Groups an array by a specific field's value.
 * @param array The array of the group by.
 * @param field The field to group by.
 * @returns An object where each key is the value of the field to group by and the
 * result is an array of the entries with that same field value.
 */
export const groupBy = <T extends Record<K, PropertyKey>, K extends keyof T>(array: T[], field: K) =>
  array.reduce(
    (accum, value) => {
      const groupByValue = value[field];
      let arrayEntry = accum[groupByValue];

      if (!arrayEntry) {
        arrayEntry = [];
        accum[groupByValue] = arrayEntry;
      }

      arrayEntry.push(value);

      return accum;
    },
    {} as GroupedArray<T, K>
  );
