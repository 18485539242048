import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AppointmentCategoryVO, ProviderVO, RoomVO, WorkingHourItemVO } from "@libs/api/generated-api";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { usePathParams } from "hooks/usePathParams";
import { RunBootstrapScheduleGuides } from "components/Practice/BootstrapScheduleGuides/RunBootstrapScheduleGuides";

export const BootstrapScheduleGuidesRoute: FC = () => {
  const { httpClient } = useContext(ApiClientContext);
  const [isScheduleGuidesEnabled, setIsScheduleGuidesEnabled] = useState(false);
  const isFetching = useBoolean(false);
  const [practiceData, setPracticeData] = useState<{
    providers: ProviderVO[];
    providerHoursByProvider: Record<number, WorkingHourItemVO[]>;
    practiceHours: WorkingHourItemVO[];
    categories: AppointmentCategoryVO[];
    rooms: RoomVO[];
    onboardingStatus: string;
    selfBookingEnabled: boolean;
  } | null>(null);
  const { practiceId } = usePathParams("practice");
  const fetchHoursRef = useRef(false);

  const fetchPracticeData = useCallback(async () => {
    isFetching.on();

    const [
      practiceResponse,
      roomsResponse,
      providersResponse,
      categoriesResponse,
      practiceHoursResponse,
      scheduleConfigResponse,
      practiceFeatureStatesResponse,
    ] = await Promise.all([
      httpClient.practices.getPractice(practiceId),
      httpClient.practices.getRoomsByPracticeId(practiceId),
      httpClient.practices.getAllProviders(practiceId, { statuses: ["ACTIVE", "PENDING"] }),
      httpClient.practices.getAppointmentCategories(practiceId),
      httpClient.practices.getWorkingHours(practiceId),
      httpClient.practices.getSchedulingConfig(practiceId),
      httpClient.practices.getPracticeFeatureStates(practiceId),
    ]);

    const providersHours = await Promise.all(
      providersResponse.data.data.map((provider) =>
        httpClient.practices
          .getEmployeeWorkingHours(practiceId, provider.id)
          .then((response) => ({ providerId: provider.id, hours: response.data.data }))
      )
    );

    const byProvider: Record<number, WorkingHourItemVO[]> = {};

    for (const providerHours of providersHours) {
      byProvider[providerHours.providerId] = providerHours.hours;
    }

    const data = {
      providers: providersResponse.data.data,
      providerHoursByProvider: byProvider,
      practiceHours: practiceHoursResponse.data.data,
      categories: categoriesResponse.data.data,
      rooms: roomsResponse.data.data,
      onboardingStatus: practiceResponse.data.data.onboardingStatus ?? "NONE",
      selfBookingEnabled: scheduleConfigResponse.data.data.selfBookingConfig.enabled,
    };

    setIsScheduleGuidesEnabled(
      practiceFeatureStatesResponse.data.data.some(
        (feature) => feature.practiceFeature.type === "SCHEDULE_GUIDES" && feature.isEnabled
      )
    );
    setPracticeData(data);
    isFetching.off();
  }, [httpClient.practices, isFetching, practiceId]);

  useEffect(() => {
    if (!fetchHoursRef.current) {
      fetchHoursRef.current = true;
      fetchPracticeData();
    }
  }, [fetchPracticeData]);

  return (
    <div className="h-full flex flex-col">
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-row justify-between">
          <Title>Bootstrap Schedule Guides</Title>
        </TitleContainer>
      </InputsHeaderRow>

      <QueryResult queries={[{ isLoading: isFetching.isOn, isError: false }]}>
        <div className="flex-1 min-h-0 overflow-y-auto">
          {isScheduleGuidesEnabled ? (
            <div>
              The practice already has schedule guides enabled so no need to bootstrap schedule guides.
            </div>
          ) : practiceData ? (
            <RunBootstrapScheduleGuides practiceId={practiceId} {...practiceData} />
          ) : null}
        </div>
      </QueryResult>
    </div>
  );
};
