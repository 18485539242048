import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { EmployeeVO } from "@libs/api/generated-api";
import { Cell, IconsCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle.svg";
import { ReactComponent as CheckCircle } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as ArchiveFolderIcon } from "@libs/assets/icons/archive-folder.svg";

import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { createMenuOptions, MenuOptions } from "@libs/components/UI/MenuOptions";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Pill } from "@libs/components/UI/Pill";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { resendEmployeeInvite } from "@libs/api/employee/mutations";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { deleteEmployees } from "api/employee/mutations";
import { handleError } from "utils/handleError";

export const EmployeeRow: React.FC<{
  practiceId: number;
  employee: EmployeeVO;
}> = ({ employee, practiceId }) => {
  const employeePending = employee.employmentDetails.status === "PENDING";
  const [
    { mutate: resendInvite, isLoading: isResending },
    { mutate: deleteEmployeesMutate, isLoading: isDeleting },
  ] = useApiMutations([resendEmployeeInvite, deleteEmployees]);

  const handleDeleteEmployee = useCallback(() => {
    deleteEmployeesMutate(
      {
        practiceId,
        action: employeePending ? "DELETE" : "ARCHIVE",
        ids: [employee.id],
      },
      {
        onError: handleError,
      }
    );
  }, [deleteEmployeesMutate, employee.id, employeePending, practiceId]);
  const handleResendInvite = useCallback(() => {
    resendInvite(
      { employeeId: employee.id, practiceId },
      {
        onError: handleError,
        onSuccess: () => {
          toast.success("Invite resent");
        },
      }
    );
  }, [employee.id, practiceId, resendInvite]);
  const menu = useBoolean(false);
  const menuOptions = useMemo(
    () => [
      ...createMenuOptions(
        {
          label: "Resend Invite",
          value: "resend",
          SvgIcon: EmailIcon,
          isLoading: isResending,
        },
        {
          label: employeePending ? "Delete" : "Archive",
          value: "delete",
          SvgIcon: employeePending ? DeleteIcon : ArchiveFolderIcon,
          isLoading: isDeleting,
        }
      ),
    ],
    [employeePending, isDeleting, isResending]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "resend": {
          handleResendInvite();

          break;
        }
        case "delete": {
          handleDeleteEmployee();
          break;
        }
        default: {
          break;
        }
      }
    },
    [handleDeleteEmployee, handleResendInvite]
  );
  const employmentStatus = employee.employmentDetails.status ?? "UNKNOWN";

  return (
    <Row key={employee.id}>
      <TextCell className="rounded-bl flex items-center">{employee.contactDetails.workEmail}</TextCell>
      <Cell className="flex items-center">
        <Pill round={false} theme={employmentStatus === "PENDING" ? "orange" : "green"}>
          {sentenceCaseConstant(employmentStatus)}
        </Pill>
      </Cell>
      <TextCell className="flex row gap-2 items-center">
        {employee.isOwner ? (
          <>
            <Icon SvgIcon={CheckCircle} className="text-green-500" />
            <span>Yes</span>
          </>
        ) : (
          <>
            <Icon SvgIcon={CancelIcon} className="text-red-500" />
            <span>No</span>
          </>
        )}
      </TextCell>
      <IconsCell className="rounded-br">
        <ButtonMenu
          menuContent={
            <div className="w-36">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          isOpen={menu.isOn}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon
              SvgIcon={VerticalMenuIcon}
              tooltip={{ content: "Action Menu", theme: "SMALL" }}
              {...props}
            />
          )}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};
