import React, { useCallback, useState } from "react";
import { produce } from "immer";
import { Form } from "@libs/components/UI/Form";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { Button } from "@libs/components/UI/Button";
import { TableGrid } from "@libs/components/UI/GridTableComponents";
import { useValidation } from "@libs/hooks/useValidation";
import { email } from "@libs/utils/validators";
import { cx } from "@libs/utils/cx";
import { formatAsISODate } from "@libs/utils/date";
import { AdminPanel } from "components/UI/AdminPanel";
import { createPracticeAdministrators } from "api/employee/mutations";
import { handleError } from "utils/handleError";

type DraftEmployee = {
  email: string;
  isOwner: boolean;
};

const getInitialDraftEmployees = (): DraftEmployee[] => [{ email: "", isOwner: false }];
const COLUMNS = [
  {
    title: "Email",
    width: "1fr",
  },

  {
    title: "Practice Owner",
    width: "auto",
  },
];

const getEmailValidationSchema = () => ({
  $validations: [
    {
      $v: (value: unknown) =>
        Array.isArray(value)
          ? value.some((draftEmployee: DraftEmployee) => draftEmployee.email.trim() !== "")
          : true,
      $error: "Please enter at least one email address.",
    },
  ],
  $items: {
    email: [
      {
        $v: email,
        $error: `Please enter a valid email address.`,
      },
    ],
  },
});

type InviteeProps = {
  email: string;
  isOwner?: boolean;
  onChangeEmail?: (email: string) => void;
  onChangeIsOwner?: (isOwner: boolean) => void;
  onClickResend?: () => void;
  emailError?: string;
  isResending?: boolean;
};

const InviteeRow: React.FC<InviteeProps> = ({
  email: emailValue,
  isOwner,
  emailError,
  onChangeEmail,
  onChangeIsOwner,
  onClickResend,
  isResending,
}) => {
  const sharedProps = {
    disabled: !onChangeEmail,
  };

  return (
    <>
      <FormFieldInput
        {...sharedProps}
        type="email"
        autoComplete="off"
        placeholder="Enter email address..."
        value={emailValue}
        error={emailError}
        onChange={(e) => onChangeEmail?.(e.target.value)}
        iconClassName={isResending ? "animate-spin" : "text-primaryTheme"}
        iconTooltip={{ content: "Resend invite", theme: "SMALL" }}
        Icon={onClickResend ? (isResending ? RefreshIcon : SendIcon) : undefined}
        iconOnClick={onClickResend}
      />

      <Checkbox
        {...sharedProps}
        className={cx(emailError && "pb-4")}
        checked={isOwner}
        onChange={(e) => onChangeIsOwner?.(e.target.checked)}
      >
        Yes
      </Checkbox>
    </>
  );
};

export const PracticeAdminsForm: React.FC<{ practiceId: number; onSuccess: Func }> = ({
  practiceId,
  onSuccess,
}) => {
  const [employeesToAdd, setEmployeesToAdd] = useState<DraftEmployee[]>(() => getInitialDraftEmployees());
  const [{ mutate: createPracticeAdministratorsMutate, isLoading }] = useApiMutations([
    createPracticeAdministrators,
  ]);

  const validation = useValidation(employeesToAdd, getEmailValidationSchema());

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      const populatedEmployees = employeesToAdd.filter((employee) => employee.email.trim() !== "");

      if (validation.validate().$isValid) {
        const now = formatAsISODate(new Date());

        const admins = populatedEmployees.map((employee) => ({
          workEmail: employee.email,
          isOwner: employee.isOwner,
          accessDate: now,
          startDate: now,
          firstName: "",
          lastName: "",
        }));

        createPracticeAdministratorsMutate(
          {
            practiceId,
            data: {
              employeeRequests: admins,
            },
          },
          {
            onSuccess,
            onError: handleError,
          }
        );
      }

      setEmployeesToAdd(populatedEmployees.length > 0 ? populatedEmployees : getInitialDraftEmployees());
    },
    [createPracticeAdministratorsMutate, employeesToAdd, onSuccess, practiceId, validation]
  );

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col h-full overflow-y-auto">
      <AdminPanel>
        <TaskProgressHeader totalSteps={3} step={1} />
        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-sansSemiBold">Administrators</h2>
          <p className="text-xs">Invite some administrators to manage this practice</p>
        </div>
        <TableGrid columnWidths={COLUMNS.map((item) => item.width)} className="gap-x-6 gap-y-3 max-w-md">
          {COLUMNS.map((column, index) => (
            <div key={index} className="font-sansSemiBold text-xs">
              {column.title}
            </div>
          ))}
          {validation.result.$error && (
            <div className="text-red-500 col-span-full text-xs">{validation.result.$error}</div>
          )}
          {employeesToAdd.map((draft, index) => {
            const error = validation.result.$items[index]?.email.$error;

            return (
              <InviteeRow
                key={index}
                isOwner={draft.isOwner}
                email={draft.email}
                emailError={error}
                onChangeEmail={(newEmail) => {
                  setEmployeesToAdd((last) =>
                    produce(last, (next) => {
                      if (next[index]) {
                        next[index].email = newEmail;
                      }
                    })
                  );
                }}
                onChangeIsOwner={(isOwner) => {
                  setEmployeesToAdd((last) =>
                    produce(last, (next) => {
                      if (next[index]) {
                        next[index].isOwner = isOwner;
                      }
                    })
                  );
                }}
              />
            );
          })}
          <Button
            className="flex items-center gap-x-2 text-xs mt-1"
            onClick={() => {
              validation.reset();
              setEmployeesToAdd((prev) => [...prev, { email: "", isOwner: false }]);
            }}
            theme="link"
          >
            <Icon SvgIcon={PlusCircleIcon} />
            Administrator
          </Button>
        </TableGrid>
      </AdminPanel>
      <div
        className={`
          flex
          gap-6
          justify-center
          flex-none
          p-4
          border-t
          border-t-slate-200
        `}
      >
        <AsyncButton isLoading={isLoading} type="submit" theme="primary" className="min-w-button">
          Next
        </AsyncButton>
      </div>
    </Form>
  );
};
