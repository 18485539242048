import { FC, PropsWithChildren, useMemo, useState } from "react";
import {
  AppointmentCategoryVO,
  ProviderVO,
  RoomVO,
  WorkingHourItemVO,
  UpsertScheduleGuideRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Banner } from "@libs/components/UI/Banner";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { cleanData, createGuides } from "components/Practice/BootstrapScheduleGuides/createScheduleGuides";
import { collectPracticeProviderInfo } from "components/Practice/BootstrapScheduleGuides/collectBootstrapStats";

type Props = {
  providers: ProviderVO[];
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>;
  practiceHours: WorkingHourItemVO[];
  categories: AppointmentCategoryVO[];
  rooms: RoomVO[];
  onboardingStatus: string;
  selfBookingEnabled: boolean;
  practiceId: number;
};

const createScheduleGuideMutation = makeMutation({
  mutationKey: ["practices", "createScheduleGuide"],
  formatParams: (args: { practiceId: number; data: UpsertScheduleGuideRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const RunBootstrapScheduleGuides: FC<PropsWithChildren<Props>> = ({
  providers,
  providerHoursByProvider,
  practiceHours,
  categories,
  rooms,
  onboardingStatus,
  selfBookingEnabled,
  practiceId,
}) => {
  const [saveState, setSaveState] = useState({ completed: false, errors: [] as unknown[], isSaving: false });
  const [{ mutateAsync: createScheduleGuide }] = useApiMutations([createScheduleGuideMutation]);

  const preRunInfo = useMemo(() => {
    const migration = createGuides(
      practiceId,
      providers,
      providerHoursByProvider,
      practiceHours,
      categories,
      rooms,
      "best"
    );

    const {
      guides,
      strategyKey,
      analytics: { mergeStrategies, ...practiceStats },
    } = migration;

    const mergeStrategyResult = strategyKey ? mergeStrategies[strategyKey] : undefined;

    const usedMergeStrategyResult = mergeStrategyResult
      ? {
          avgMaxRoomColCount: mergeStrategyResult.avgMaxRoomColCount,
          totalGuides: mergeStrategyResult.totalGuides,
          maxRoomGuidesColCount: mergeStrategyResult.maxRoomGuidesColCount,
        }
      : {
          avgMaxRoomColCount: 0,
          totalGuides: 0,
          maxRoomGuidesColCount: 0,
        };

    return {
      guides,
      practiceStats: {
        onboardingStatus,
        selfBookingEnabled,
        ...practiceStats,
      },
      boostrapStats: {
        ...collectPracticeProviderInfo({
          ...cleanData(providers, providerHoursByProvider, practiceHours, categories, rooms),
          providerHoursByProvider,
          selfBookingEnabled,
        }),
        ...usedMergeStrategyResult,
        strategyKey,
      },
    };
  }, [
    categories,
    onboardingStatus,
    practiceHours,
    practiceId,
    providerHoursByProvider,
    providers,
    rooms,
    selfBookingEnabled,
  ]);

  const handleBootstrapPractice = async () => {
    setSaveState({ errors: [], isSaving: true, completed: false });

    const errors = [];

    for (const guide of preRunInfo.guides) {
      try {
        await createScheduleGuide({ practiceId, data: guide });
      } catch (e) {
        console.error(e);
        errors.push(e);
      }
    }
    setSaveState({ errors, isSaving: true, completed: true });
  };

  return (
    <div className="p-10 text-sm">
      <div className="mx-auto max-w-2xl mb-6">
        <h2 className="font-sansSemiBold mb-2">Practice Status</h2>
        <div
          className={`
            whitespace-pre-wrap
            font-[monospace]
            bg-slate-600
            text-white
            rounded
            p-6
          `}
        >
          {JSON.stringify(preRunInfo.practiceStats, null, 2)}
        </div>
      </div>
      <div className="mx-auto max-w-2xl">
        <h2 className="font-sansSemiBold mb-2">Proposed schedule guides</h2>
        <div
          className={`
            whitespace-pre-wrap
            font-[monospace]
            bg-slate-600
            text-white
            rounded
            p-6
          `}
        >
          {JSON.stringify(preRunInfo.boostrapStats, null, 2)}
        </div>
      </div>
      <div className="flex justify-center mt-10">
        {preRunInfo.boostrapStats.totalGuides === 0 ? (
          <Banner theme="error">No Schedule guides can be created for the practice</Banner>
        ) : saveState.completed ? (
          saveState.errors.length ? (
            <div className="flex flex-col gap-y-4">
              <Banner theme="error">There were errors creating schedule guides</Banner>
              <div className="mx-auto max-w-2xl">
                <div
                  className={`
                    whitespace-pre-wrap
                    font-[monospace]
                    bg-slate-600
                    text-white
                    rounded
                    p-6
                  `}
                >
                  {JSON.stringify(saveState.errors, null, 2)}
                </div>
              </div>
            </div>
          ) : (
            <Banner theme="success">Schedule Guides Created!</Banner>
          )
        ) : (
          <AsyncButton onClick={handleBootstrapPractice} isLoading={saveState.isSaving}>
            Create Schedule Guides
          </AsyncButton>
        )}
      </div>
    </div>
  );
};
