import { FC } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { sentenceCaseConstant } from "@libs/utils/casing";

type AdminOnboardingStatus = NonNullable<PracticeInfoVO["onboardingStatus"]> | "STATUS_NOT_SET";

const onboardingStatusToColor: Record<AdminOnboardingStatus, PillProps["theme"]> = {
  LAUNCHED: "green",
  READY: "blue",
  ONBOARDING: "orange",
  PROVISIONING: "yellow",
  CHURNED: "slate100",
  STATUS_NOT_SET: "red",
};

export const OnboardingStatusPill: FC<{ status: PracticeInfoVO["onboardingStatus"] }> = ({ status }) => {
  const adminStatus: AdminOnboardingStatus = status ?? "STATUS_NOT_SET";

  return (
    <Pill className="w-fit" round={false} theme={onboardingStatusToColor[adminStatus]}>
      {sentenceCaseConstant(adminStatus)}
    </Pill>
  );
};
