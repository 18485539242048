import { differenceInMinutes, isAfter, isEqual } from "date-fns";
import { ANY_DAY, getLocalDate } from "@libs/utils/date";
import { Some } from "@libs/utils/array";

import { TimeRangeVO } from "@libs/api/generated-api";
import { groupBy as groupItemsBy } from "@libs/utils/groupBy";

export type ExpandedEvent = {
  resource: number;
  start: string;
  end: string;
};

export type ExpandedEventWithColumn<T extends ExpandedEvent> = T & {
  column: { index: number; count: number };
};

export type ExpandedEventMaybeColumn<T extends ExpandedEvent> = T & {
  column?: { index: number; count: number };
};

const findGap = <T extends ExpandedEvent>(stackedColumns: Some<T>[], event: T) => {
  for (const [colIndex, column] of stackedColumns.entries()) {
    for (const [rowIndex, row] of column.entries()) {
      const startTimeIsAfterEnd =
        isEqual(getLocalDate(ANY_DAY, event.start), getLocalDate(ANY_DAY, row.end)) ||
        isAfter(getLocalDate(ANY_DAY, event.start), getLocalDate(ANY_DAY, row.end));

      if (!startTimeIsAfterEnd) {
        continue;
      }

      const nextRow = column[rowIndex + 1];

      if (nextRow) {
        const newRowStartTimeIsAfterEnd =
          isEqual(getLocalDate(ANY_DAY, nextRow.start), getLocalDate(ANY_DAY, event.end)) ||
          isAfter(getLocalDate(ANY_DAY, nextRow.start), getLocalDate(ANY_DAY, event.end));

        if (newRowStartTimeIsAfterEnd) {
          return { row: rowIndex + 1, column: colIndex };
        }

        continue;
      }

      return { row: rowIndex + 1, column: colIndex };
    }
  }

  return undefined;
};

const compressColumns = <T extends ExpandedEvent>(stack: Some<T>) => {
  const stackedColumns = stack.map((item) => [item] as Some<T>);
  let i = 1;

  while (i < stackedColumns.length) {
    const column = stackedColumns[i];

    if (!column) {
      break;
    }

    const expandedEvent = column[0];

    const gap = findGap(stackedColumns.slice(0, i), expandedEvent);

    if (gap) {
      stackedColumns.splice(i, 1);

      const gapColumn = stackedColumns[gap.column];

      if (gapColumn) {
        gapColumn.splice(gap.row, 0, expandedEvent);
      }
    } else {
      i++;
    }
  }

  return stackedColumns;
};

const flattenColumns = <T extends ExpandedEvent>(compressed: Some<T>[]) => {
  const count = compressed.length;

  return compressed.flatMap((column, colIndex) =>
    column.map((row) => ({ ...row, column: { index: colIndex, count } }))
  );
};

const addResourceEventColumns = <T extends ExpandedEvent>(expandedEvents: T[]) => {
  const sortedGuideEvents = [...expandedEvents];

  sortedGuideEvents.sort((a, b) => {
    const dateAStartTime = getLocalDate(ANY_DAY, a.start);
    const dateBStartTime = getLocalDate(ANY_DAY, b.start);
    const startTimeDiff = dateAStartTime.getTime() - dateBStartTime.getTime();

    if (startTimeDiff === 0) {
      const dateAEndTime = getLocalDate(ANY_DAY, a.end);
      const dateBEndTime = getLocalDate(ANY_DAY, b.end);

      return (
        differenceInMinutes(dateAEndTime, dateAStartTime) - differenceInMinutes(dateBEndTime, dateBStartTime)
      );
    }

    return startTimeDiff;
  });

  const [first, ...rest] = sortedGuideEvents;

  if (!first) {
    return {
      intervals: [],
      events: [],
      colCount: 0,
    };
  }

  const stacks: Some<{ startTime: string; endTime: string; events: Some<T> }> = [
    { startTime: first.start, endTime: first.end, events: [first] },
  ];
  let stackIndex = 0;

  for (const expandedEvent of rest) {
    const currentStack = stacks[stackIndex];

    if (!currentStack) {
      continue;
    }

    const lastGuideEvent = currentStack.events.at(-1);

    if (!lastGuideEvent) {
      continue;
    }

    const maxEndTimeDate = getLocalDate(ANY_DAY, currentStack.endTime);

    if (
      isEqual(getLocalDate(ANY_DAY, expandedEvent.start), maxEndTimeDate) ||
      isAfter(getLocalDate(ANY_DAY, expandedEvent.start), maxEndTimeDate)
    ) {
      stackIndex += 1;
      stacks.push({
        startTime: expandedEvent.start,
        endTime: expandedEvent.end,
        events: [expandedEvent],
      });
    } else {
      currentStack.events.push(expandedEvent);
      currentStack.endTime = isAfter(getLocalDate(ANY_DAY, expandedEvent.end), maxEndTimeDate)
        ? expandedEvent.end
        : currentStack.endTime;
    }
  }

  let colCount = 0;
  let uncompressed: ExpandedEventWithColumn<T>[] = [];

  for (const stack of stacks) {
    const compressed = compressColumns(stack.events);

    colCount = Math.max(compressed.length, colCount);
    uncompressed = [...uncompressed, ...flattenColumns(compressed)];
  }

  return {
    intervals: stacks.map((stack) => ({
      startTime: stack.startTime,
      endTime: stack.endTime,
    })),
    events: uncompressed,
    colCount,
  };
};

export const addEventColumns = <T extends ExpandedEvent>(expandedEvents: T[], resourceIds: number[]) => {
  const eventsByResource = groupItemsBy(expandedEvents, "resource");
  const eventColCountPerResource: Record<number, number | undefined> = {};
  const resourceIntervals: { id: number; ranges: TimeRangeVO[] }[] = [];
  let events: ExpandedEventWithColumn<T>[] = [];

  for (const resourceId of resourceIds) {
    const resourceEvents = eventsByResource[resourceId as T["resource"]];

    if (resourceEvents) {
      const resourceEventsWithColumn = addResourceEventColumns(resourceEvents);

      resourceIntervals.push({
        id: resourceId,
        ranges: resourceEventsWithColumn.intervals,
      });
      eventColCountPerResource[resourceId] = resourceEventsWithColumn.colCount;
      events = [...events, ...resourceEventsWithColumn.events];
    }
  }

  return {
    resourceIntervals,
    colCountPerResource: eventColCountPerResource,
    events,
  };
};
