import React, { useCallback, useState } from "react";
import { useDebounceWithPendingStatus } from "@libs/hooks/useDebounceWithPendingStatus";

const SEARCH_DEBOUNCE_DELAY_MS = 500;

export const useDebouncedSearch = (searchString: string) => {
  const lastSearchString = React.useRef<Set<string>>(new Set([]));
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>(searchString);
  const debouncedSetSearchString = useCallback((val: string) => {
    setDebouncedSearchString(val);
  }, []);
  const {
    callback: updateSearchString,
    isPending: isDebouncePending,
    cancel,
  } = useDebounceWithPendingStatus(debouncedSetSearchString, SEARCH_DEBOUNCE_DELAY_MS, {
    leading: false,
    trailing: true,
  });

  React.useEffect(() => {
    lastSearchString.current.add(debouncedSearchString);
  }, [debouncedSearchString]);
  React.useEffect(() => {
    updateSearchString(searchString);
  }, [searchString, updateSearchString]);

  const hasSearchedThisStringPrior = lastSearchString.current.has(searchString);

  return {
    search: hasSearchedThisStringPrior ? searchString : debouncedSearchString,
    isWaiting: hasSearchedThisStringPrior ? false : isDebouncePending,
    cancel,
  };
};
