import { captureException } from "@sentry/react";
import { FC, useCallback, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getTokenErrorReason } from "@libs/utils/cognito";
import { ApiClientProvider } from "@libs/contexts/ApiClientContext";
import { getTokensForApi } from "@libs/auth/getTokens";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useEnvContext } from "contexts/EnvContext";
import { useSignoutWithReason } from "hooks/useSignoutWithReason";
import { headers } from "utils/headers";

export const AdminApiClientProvider: FC = () => {
  const env = useEnvContext();
  const { localStorage } = useStorageContext();

  const location = useLocation();
  const locationRef = useRef(location);

  // referencing locationRef vs location
  // because we need to keep this function a stable reference
  locationRef.current = location;

  const signOutWithReason = useSignoutWithReason();

  const handleRequestTokens = useCallback(async () => {
    try {
      return await getTokensForApi(localStorage);
    } catch (e) {
      const reason = getTokenErrorReason(e);

      if (!reason) {
        captureException(e);
      }

      signOutWithReason(reason);
      throw e;
    }
  }, [signOutWithReason, localStorage]);

  return (
    <ApiClientProvider
      baseUrl={env.REACT_APP_API_HOST}
      headers={headers}
      onRequestTokens={handleRequestTokens}
    >
      <Outlet />
    </ApiClientProvider>
  );
};
