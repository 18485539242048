import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Sentry
import { captureException, setUser as sentrySetUser } from "@sentry/react";
import { signOut } from "@aws-amplify/auth";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getPostSignOutStorage } from "storage/postSignOut";
import { AdminRouterContext } from "router/types";
import { getAdminActivityStorage } from "storage/activity";
import { isSupportIdentityToken } from "utils/auth";

export const loader =
  ({ storage }: AdminRouterContext) =>
  async () => {
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getAdminActivityStorage(storage.localStorage);

    if (activityStorage.isRecentlyActive() && tokens.identity) {
      return {
        email: tokens.identity.email,
        accountToken: tokens.account,
        isSupportUser: isSupportIdentityToken(tokens.identity),
      };
    }

    return {
      email: undefined,
      accountToken: undefined,
      isSupportUser: false,
    };
  };

export const SignOutRoute: FC = () => {
  const { accountToken, email, isSupportUser } = useCurriedLoaderData<typeof loader>();
  const storage = useStorageContext();
  const navigate = useNavigate();

  const { query } = useQueryParams("signOut");
  const currentUserId = accountToken?.userId;
  const executeSignOut = useCallback(async () => {
    try {
      if (email && query.signOutReason) {
        const accountTokenStorage = getAccountTokenStorage(storage.localStorage);

        accountTokenStorage.clearAccountToken(email);
      }

      const postSignOutStorage = getPostSignOutStorage(storage.localStorage);

      postSignOutStorage.setPostSignOutParams({
        signOutReason: query.signOutReason,
        // A returnUrl can only be added if we have the currentUserId. This
        // allows us to check the next person signing in matches the last,
        // preventing users from signing in to multiple accounts on the same
        // machine and returning to a url that doesn't apply to their current
        // account.
        ...(query.returnUrl && currentUserId
          ? { returnUrl: query.returnUrl, lastUserId: currentUserId }
          : undefined),
        to: query.to,
        isSupportUser,
      });
      (window as Window).analytics?.reset();

      await signOut();
      sentrySetUser(null);

      // Federated sign-out for support users will never have the below be
      // called. Instead, amplify signOut() for federated users rediects them to
      // postSignOut via the configured cognito logout url.
      navigate(paths.postSignOut());
    } catch (e) {
      captureException(e);
    }
  }, [
    query.signOutReason,
    query.returnUrl,
    query.to,
    currentUserId,
    email,
    storage,
    isSupportUser,
    navigate,
  ]);

  useEffect(() => {
    executeSignOut();
  }, [executeSignOut]);

  return <LoadingOverlaySpinner centerVertically loadingText="Signing Out" />;
};
