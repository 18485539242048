import { FormEvent, PropsWithChildren, useCallback, useState } from "react";
import { PracticeFeatureStateVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { batchUpdatePracticeFeatureState } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { AdminPanel } from "components/UI/AdminPanel";

export const PracticeFeaturesForm: React.FC<
  PropsWithChildren<{
    features: PracticeFeatureStateVO[];
    isEditing: boolean;
    practiceId: number;
    onClickSecondaryButton?: Func;
    onSubmissionSuccess: Func;
    primaryButtonText?: string;
    secondaryButtonText?: string;
  }>
> = ({
  features,
  isEditing,
  children,
  onClickSecondaryButton,
  onSubmissionSuccess,
  practiceId,
  primaryButtonText = "Save",
  secondaryButtonText = "Cancel",
}) => {
  const [featureDraft, setFeatureDraft] = useState(features);
  const [{ mutate: updateFeaturesMutate, isLoading }] = useApiMutations([batchUpdatePracticeFeatureState]);
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const featuresChanged = featureDraft.filter(
        (feature) =>
          feature.isEnabled !==
          features.find((f) => f.practiceFeature.id === feature.practiceFeature.id)?.isEnabled
      );

      if (featuresChanged.length > 0) {
        updateFeaturesMutate(
          {
            practiceId,
            data: featuresChanged.map(({ practiceFeature, isEnabled }) => {
              return {
                practiceFeatureId: practiceFeature.id,
                isEnabled,
              };
            }),
          },
          {
            onSuccess: onSubmissionSuccess,
            onError: handleError,
          }
        );
      } else {
        onSubmissionSuccess();
      }
    },
    [featureDraft, features, onSubmissionSuccess, practiceId, updateFeaturesMutate]
  );

  return (
    <Form className="flex flex-col h-full min-h-0" onSubmit={handleSubmit}>
      <AdminPanel>
        {children}
        <div className="grid grid-cols-2 gap-6">
          {featureDraft.map(({ practiceFeature, isEnabled }) => {
            return (
              <FormFieldSwitch
                key={practiceFeature.id}
                label={practiceFeature.friendlyName}
                checked={isEnabled}
                edit={isEditing}
                onChange={(e) => {
                  setFeatureDraft((prev) =>
                    prev.map((item) => {
                      if (item.practiceFeature.id === practiceFeature.id) {
                        return { ...item, isEnabled: e.target.checked };
                      }

                      return item;
                    })
                  );
                }}
                falseText="Disabled"
                trueText="Enabled"
                disabled={false}
              />
            );
          })}
        </div>
      </AdminPanel>
      {isEditing && (
        <div
          className={`
            flex
            gap-6
            justify-center
            flex-none
            p-4
            border-t
            border-t-slate-200
          `}
        >
          {onClickSecondaryButton && (
            <Button onClick={onClickSecondaryButton} theme="secondary" className="min-w-button">
              {secondaryButtonText}
            </Button>
          )}
          <AsyncButton isLoading={isLoading} type="submit" theme="primary" className="min-w-button">
            {primaryButtonText}
          </AsyncButton>
        </div>
      )}
    </Form>
  );
};
