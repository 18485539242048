import ReactDOMClient from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";
import { wrapCreateBrowserRouterV6 } from "@sentry/react";
import { REACT_RENDER_ROOT } from "@libs/utils/constants";
import { getLocalStorage, getSessionStorage } from "@libs/storage/getStorage";
import { queryClient } from "@libs/api/queryClient";
import { env } from "env";
import { getEntryPoint } from "components/Main/EntryPoint";

const { Component } = getEntryPoint({
  createRouter: wrapCreateBrowserRouterV6(createBrowserRouter),
  queryClient,
  storage: { localStorage: getLocalStorage(), sessionStorage: getSessionStorage() },
  envValues: env,
});

const container = document.querySelector(`#${REACT_RENDER_ROOT}`);

const root = ReactDOMClient.createRoot(container!);

root.render(<Component />);
