import { FC, useCallback, useMemo } from "react";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import {
  HeaderCell,
  InputsHeaderRow,
  TableGrid,
  Title,
  TitleContainer,
} from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { MainContent } from "@libs/components/UI/MainContent";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { listPractices } from "api/user/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";
import { PracticesTabQueryParams } from "utils/routing/paths";
import { PracticeRow } from "components/Practices/PracticeRow";

type HeaderItem = {
  width: string;
  label?: string;
  id: string;
};

const HEADERS: HeaderItem[] = [
  {
    width: "6rem",
    label: "ID",
    id: "id",
  },
  {
    width: "1fr",
    label: "Name",
    id: "name",
  },
  {
    width: "auto",
    label: "Status",
    id: "status",
  },
  {
    width: "1fr",
    label: "Owners",
    id: "owners",
  },
  // {
  //   width: "1fr",
  //   label: "Types",
  //   id: "types",
  // },
  // {
  //   width: "1fr",
  //   label: "Status",
  //   id: "status",
  // },
  // {
  //   width: "3rem",
  //   id: "launch-practice",
  // },
];

const PAGE_SIZE = 50;

export const getQueryFiltersProps = (params: PracticesTabQueryParams) => {
  return toFilterComponentsProps(params, [
    {
      type: "default",
      prop: "search",
      format: (val) => `Search: ${val.trim()}`,
    },
  ]);
};

export const PracticesRoute: FC = () => {
  const { query, updateQuery } = useQueryParams("practicesTab");
  const { search: searchString, isWaiting } = useDebouncedSearch(query.search ?? "");

  usePageTitle("Admin Portal - Practices");

  const listPracticesQuery = useInfiniteApiQuery(
    listPractices({
      args: {
        searchString,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: {
        enabled: !isWaiting,
      },
    })
  );

  const practices = useFlattenPages(listPracticesQuery.data);
  const pagingDetails = useMemo(
    () => getInfiniteQueryPagingDetails(listPracticesQuery.data),
    [listPracticesQuery.data]
  );
  const queryFiltersProps = useMemo(() => getQueryFiltersProps(query), [query]);

  const totalElements = pagingDetails?.totalElements ?? 0;

  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: listPracticesQuery });
  const handleUpdateFilterQueryParams = useCallback(
    (params: Partial<PracticesTabQueryParams>) => updateQuery("replaceIn", params),
    [updateQuery]
  );

  return (
    <MainContent>
      <div className="flex flex-col h-full">
        <LayoutCard className="flex flex-col flex-1 min-h-0 border-t border-t-slate-200">
          <InputsHeaderRow className="flex items-center">
            <TitleContainer className="flex flex-none items-center gap-x-1">
              <Title>Practices</Title>
            </TitleContainer>
          </InputsHeaderRow>
          <QueryFilters
            queries={[listPracticesQuery]}
            totalElements={totalElements}
            onUpdateParams={handleUpdateFilterQueryParams}
            searchParamKey="search"
            {...queryFiltersProps}
          />
          <PersistScrollPosition
            id="practicesTable"
            className="flex-1 min-h-0 overflow-y-auto relative z-0"
            ref={rootRef}
          >
            <ScrollableInfiniteQueryResult
              infiniteQuery={listPracticesQuery}
              loading={<LoadingContent className="flex rounded-none" />}
              scrollRef={scrollRef}
            >
              <TableGrid columnWidths={HEADERS.map(({ width }) => width)}>
                {HEADERS.map(({ id, label }) => {
                  return (
                    <HeaderCell size="short" key={id}>
                      {label}
                    </HeaderCell>
                  );
                })}
                {practices?.map((practice) => <PracticeRow key={practice.id} practice={practice} />)}
              </TableGrid>
            </ScrollableInfiniteQueryResult>
          </PersistScrollPosition>
        </LayoutCard>
      </div>
    </MainContent>
  );
};
