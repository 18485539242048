import { useCallback } from "react";
import { Channels, browserTabId, useBroadcastChannelEffect } from "@libs/hooks/useBroadcastChannel";
import { SignOutReason } from "@libs/router/signOutReasons";

export type SignInPayload = { type: "signIn"; rememberMe?: boolean };
export type SignOutPayload = { type: "signOut"; reason?: SignOutReason; addReturnUrl: boolean };
type AuthMessages = SignOutPayload | SignInPayload;
type AuthMessageHandler = (e: MessageEvent<AuthMessages & { browserTabId: string }>) => void;

export const useAuthChannelListeners = (events?: {
  onSignOut?: (data: SignOutPayload) => void;
  onSignIn?: (data: SignInPayload) => void;
}) => {
  const handleAuthMessage: AuthMessageHandler = useCallback(
    (e) => {
      // only listen to messages from other tabs
      if (e.data.browserTabId === browserTabId) {
        return;
      }

      switch (e.data.type) {
        case "signOut": {
          events?.onSignOut?.(e.data);
          break;
        }
        case "signIn": {
          events?.onSignIn?.(e.data);
          break;
        }
        default:
        // do nothing
      }
    },
    [events]
  );

  useBroadcastChannelEffect({ name: Channels.auth, onMessage: handleAuthMessage });
};

export const postAuthChannelMessage = (message: AuthMessages) => {
  const channel = new BroadcastChannel(Channels.auth);

  channel.postMessage({
    ...message,
    browserTabId,
  });
};
