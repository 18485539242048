import { FC } from "react";
import { Outlet } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Title } from "@libs/components/UI/GridTableComponents";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { MainContent } from "@libs/components/UI/MainContent";
import { SideNavigationTab } from "@libs/components/UI/SideNavigationTab";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ReactComponent as BuildingIcon } from "@libs/assets/icons/building.svg";
import { usePathParams } from "hooks/usePathParams";
import { paths, PracticeTab } from "utils/routing/paths";
import { getPracticeDataQuery } from "api/practice/queries";
import { OnboardingStatusPill } from "components/UI/OnboardingStatusPill";

const tabs: { id: PracticeTab; label: string }[] = [
  {
    id: "info",
    label: "Information",
  },
  {
    id: "employees",
    label: "Employees",
  },
  {
    id: "features",
    label: "Features",
  },
  {
    id: "server-access",
    label: "Server Access",
  },
  {
    id: "migration-settings",
    label: "Migration Settings",
  },
  {
    id: "bootstrap-schedule-guides",
    label: "Bootstrap Schedule Guides",
  },
];

export const PracticeLayout: FC = () => {
  const { practiceId } = usePathParams("practice");
  const [practiceQuery] = useApiQueries([getPracticeDataQuery({ args: { practiceId } })]);

  return (
    <div className="flex flex-row h-full bg-slate-100">
      <div className="w-[296px] py-3 px-6">
        <div className="flex flex-col gap-3 px-3">
          <QueryResult queries={[practiceQuery]} loading={<Skeleton count={3} className="h-6" />}>
            <div className="flex items-center gap-3">
              <BuildingIcon />
              <div className="flex flex-col">
                <Title>{practiceQuery.data?.doingBusinessAs}</Title>
                <p className="text-sm">ID: {practiceId}</p>
              </div>
            </div>
            <OnboardingStatusPill status={practiceQuery.data?.onboardingStatus} />
          </QueryResult>
        </div>

        <div className="flex flex-col mt-3">
          {tabs.map(({ id, label }) => (
            <SideNavigationTab replace key={id} to={paths.practice({ practiceId, tab: id })}>
              {label}
            </SideNavigationTab>
          ))}
        </div>
      </div>
      <div className="flex-1">
        <MainContent>
          <div className="flex flex-col h-full">
            <LayoutCard className="flex flex-col flex-1 min-h-0 border-t border-t-slate-200">
              <Outlet />
            </LayoutCard>
          </div>
        </MainContent>
      </div>
    </div>
  );
};
