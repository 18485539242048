import { isDefined } from "@libs/utils/types";

// Same as object.keys, but provides union of types vs. string values
export const keys = <T extends string>(obj: Record<T, unknown> | Partial<Record<T, unknown>>) => {
  return Object.keys(obj) as T[];
};

type UnflattenedObject<T> = {
  [K in keyof T]: T[K] extends Record<string, unknown> ? UnflattenedObject<T[K]> : T[K];
};

export const unflattenKeys = <T extends Record<string, unknown>>(flatObject: T) => {
  const unflattenedObject = {} as UnflattenedObject<T>;

  for (const flatKey in flatObject) {
    const keyParts = flatKey.split(".");

    keyParts.reduce((object, key, index) => {
      (object as Record<string, unknown>)[key] =
        index === keyParts.length - 1 ? flatObject[flatKey] : object[key] || {};

      return object[key] as UnflattenedObject<T>;
    }, unflattenedObject);
  }

  return unflattenedObject as T;
};

export const flattenKeys = <T extends Record<string, unknown>>(unflatObject: T) => {
  const flattenedObject: Record<string, unknown> = {};

  const flatten = (object: Record<string, unknown>, prefix = "") => {
    for (const key in object) {
      const value = object[key];

      if (value?.constructor.name === "Object") {
        flatten(value as Record<string, unknown>, `${prefix}${key}.`);
      } else {
        flattenedObject[`${prefix}${key}`] = value === null ? undefined : value;
      }
    }
  };

  flatten(unflatObject);

  return flattenedObject as T;
};

export const removeEmptyValues = <T extends Record<string, unknown>>(object: T) => {
  const result: Record<string, unknown> = {};

  for (const key in object) {
    const value = object[key];
    const name = value?.constructor.name;

    if (isDefined(name)) {
      switch (name) {
        case "Object": {
          const nestedObject = removeEmptyValues(value as Record<string, unknown>);

          result[key] =
            nestedObject && Object.values(nestedObject).every((v) => v === undefined)
              ? undefined
              : nestedObject;
          break;
        }
        case "Array": {
          result[key] = (value as Array<T>).length === 0 ? undefined : value;
          break;
        }
        case "Set":
        case "Map": {
          result[key] = (value as Set<T>).size === 0 ? undefined : value;
          break;
        }

        default: {
          result[key] = value === null ? undefined : value;
          break;
        }
      }
    } else {
      result[key] = value === null ? undefined : value;
    }
  }

  return Object.values(result).every((v) => v === undefined) ? undefined : (result as T);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const omit = <T extends Record<string, any>, K extends keyof T>(
  object: T,
  omitKeys: K[]
): Omit<T, K> => {
  const result = { ...object };

  omitKeys.forEach((key) => {
    delete result[key];
  });

  return result;
};
