import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { redirectLoader } from "@libs/router/redirectLoader";
import { NoInternetConnection } from "@libs/components/UI/NoInternetConnection";
import { PracticeFeaturesRoute } from "components/Practice/PracticeFeatures/PracticeFeaturesRoute";
import { NotFoundRoute } from "components/NotFoundRoute";
import { SentryRouteErrorFallback } from "components/Main/SentryRouteErrorFallback";
import { SignOutRoute, loader as signOutLoader } from "components/Main/SignOutRoute";
import { PostSignOutRoute } from "components/Main/PostSignOutRoute";
import { SignedInApp, loader as signedInAppLoader } from "components/Main/SignedInApp";
import { authLoader } from "components/SignIn/authLoader";
import { OAuthSignIn } from "components/SignIn/OAuthSignIn";
import { paths, routesConfig } from "utils/routing/paths";
import { GoogleSignIn } from "components/SignIn/GoogleSignIn";
import { AdminRouterContext } from "router/types";
import { AdminApiClientProvider } from "contexts/AdminApiClientProvider";
import { SessionIdleTimerRoute } from "components/Main/SessionIdleTimerRoute";
import { PracticesRoute } from "components/Practices/PracticesRoute";
import { AuthLayout } from "components/Main/AuthLayout";
import { PracticeLayout } from "components/Practice/PracticeLayout";
import { PracticeInfoRoute } from "components/Practice/PracticeInfo/PracticeInfoRoute";
import { ServerAccessRoute } from "components/Practice/ServerAccessRoute";
import { MigrationSettingsRoute } from "components/Practice/MigrationSettings/MigrationSettingsRoute";
import { CreatePracticeLayout } from "components/CreatePractice/CreatePracticeLayout";
import { CreatePracticeDetailsRoute } from "components/CreatePractice/CreatePracticeDetailsRoute";
import { SetupPracticeFeaturesRoute } from "components/CreatePractice/SetupPracticeFeaturesRoute";
import { SetupPracticeAdminsRoute } from "components/CreatePractice/SetupPracticeAdminsRoute";
import { EmployeesTabRoute } from "components/Practice/EmployeesTab/EmployeesTabRoute";
import { BootstrapScheduleGuidesRoute } from "components/Practice/BootstrapScheduleGuides/BootstrapScheduleGuidesRoute";

export const getRoutes = (context: AdminRouterContext) => {
  return [
    {
      element: (
        <>
          <Outlet />
          <ToastContainer
            className="z-20"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NoInternetConnection />
        </>
      ),
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          path: "sign-out",
          element: <SignOutRoute />,
          loader: signOutLoader(context),
        },
        {
          path: "post-sign-out",
          element: <PostSignOutRoute />,
        },
        {
          path: "auth/sign-in",
          element: <GoogleSignIn />,
          loader: authLoader(context),
        },
        {
          path: "oauth/sign-in",
          element: <OAuthSignIn />,
        },
        {
          path: "sign-in",
          loader: redirectLoader(paths.signIn()),
        },
        {
          element: <AdminApiClientProvider />,
          children: [
            {
              element: <SessionIdleTimerRoute />,
              children: [
                {
                  element: <SignedInApp />,
                  loader: signedInAppLoader(context),
                  children: [
                    {
                      element: <AuthLayout />,
                      children: [
                        {
                          index: true,
                          loader: redirectLoader(paths.practicesTab()),
                        },
                        {
                          path: "practices",
                          element: <PracticesRoute />,
                        },
                        {
                          element: <CreatePracticeLayout />,
                          children: [
                            {
                              path: "create-practice",
                              element: <CreatePracticeDetailsRoute />,
                            },
                            {
                              path: routesConfig.setupPracticeAdmins.path,
                              element: <SetupPracticeAdminsRoute />,
                            },
                            {
                              path: routesConfig.setupPracticeFeatures.path,
                              element: <SetupPracticeFeaturesRoute />,
                            },
                          ],
                        },
                        {
                          element: <PracticeLayout />,
                          children: [
                            {
                              path: "practices/:practiceId/info",
                              element: <PracticeInfoRoute />,
                            },
                            {
                              path: "practices/:practiceId/employees",
                              element: <EmployeesTabRoute />,
                            },
                            {
                              path: "practices/:practiceId/features",
                              element: <PracticeFeaturesRoute />,
                            },
                            {
                              path: "practices/:practiceId/server-access",
                              element: <ServerAccessRoute />,
                            },
                            {
                              path: "practices/:practiceId/migration-settings",
                              element: <MigrationSettingsRoute />,
                            },
                            {
                              path: "practices/:practiceId/bootstrap-schedule-guides",
                              element: <BootstrapScheduleGuidesRoute />,
                            },
                          ],
                        },
                        {
                          path: "*",
                          element: <NotFoundRoute />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
