import { useCallback, useMemo } from "react";
import { EmployeeVO } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import {
  FlyoverContent,
  FlyoverFooter,
  FlyoverForm,
  FlyoverHeader,
} from "@libs/components/UI/FlyoverComponents";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useValidation } from "@libs/hooks/useValidation";
import { formatAsISODate, nowInTimezone } from "@libs/utils/date";
import { isString } from "@libs/utils/types";
import { email, hasValue } from "@libs/utils/validators";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { createPracticeAdministrators } from "api/employee/mutations";
import { handleError } from "utils/handleError";
import { getPracticeInfoById } from "api/practice/queries";

type DraftEmployee = {
  email: string;
  isOwner: boolean;
};

const getSchema = (emailsInvited: Set<string>) => {
  return {
    email: [
      {
        $v: email,
        $error: `Please enter a valid email address`,
      },
      {
        $v: (value: unknown) => hasValue(value) && isString(value) && !emailsInvited.has(value),
        $error: `This email has already been invited`,
      },
    ],
  };
};

export const CreateAdminFlyoverForm: React.FC<{
  adminEmployees?: EmployeeVO[];
  onClose: Func;
  practiceId: number;
}> = ({ adminEmployees, onClose, practiceId }) => {
  const [draft, updateDraft] = useObjectState<DraftEmployee>(() => ({
    email: "",
    isOwner: false,
  }));
  const [practiceQuery] = useApiQueries([getPracticeInfoById({ args: { practiceId } })]);

  const schema = useMemo(() => {
    const emailsInvited = new Set((adminEmployees ?? []).map((item) => item.contactDetails.workEmail));

    return getSchema(emailsInvited);
  }, [adminEmployees]);
  const validation = useValidation(draft, schema);
  const [{ mutate: createPracticeAdministratorsMutate, isLoading }] = useApiMutations([
    createPracticeAdministrators,
  ]);

  // If a practice is provisioning, they don't yet have a real timezone.
  const practiceTimezoneId =
    practiceQuery.data?.onboardingStatus === "PROVISIONING" ? undefined : practiceQuery.data?.timezoneId;
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      if (validation.validate().$isValid) {
        const now = formatAsISODate(practiceTimezoneId ? nowInTimezone(practiceTimezoneId) : new Date());

        createPracticeAdministratorsMutate(
          {
            practiceId,
            data: {
              employeeRequests: [
                {
                  workEmail: draft.email,
                  isOwner: draft.isOwner,
                  accessDate: now,
                  startDate: now,
                  firstName: "",
                  lastName: "",
                },
              ],
            },
          },
          {
            onSuccess: onClose,
            onError: handleError,
          }
        );
      }
    },
    [
      createPracticeAdministratorsMutate,
      draft.email,
      draft.isOwner,
      onClose,
      practiceId,
      practiceTimezoneId,
      validation,
    ]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverHeader size="sm" onClose={onClose}>
        Add Administrator
      </FlyoverHeader>
      <FlyoverContent className="flex flex-col gap-6">
        <FormFieldInput
          type="email"
          autoComplete="off"
          label="Work Email"
          placeholder="Enter email address..."
          value={draft.email}
          error={validation.result.email.$error}
          onChange={(e) => {
            updateDraft({ email: e.target.value });
          }}
        />
        <FormFieldSwitch
          label="Practice Owner"
          checked={draft.isOwner}
          onChange={(e) => {
            updateDraft({ isOwner: e.target.checked });
          }}
        />
      </FlyoverContent>
      {
        // Fetching practice timezone is required to save
      }
      <QueryResult queries={[practiceQuery]}>
        <FlyoverFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton type="submit" className="min-w-button" theme="primary" isLoading={isLoading}>
            Save
          </AsyncButton>
        </FlyoverFooter>
      </QueryResult>
    </FlyoverForm>
  );
};
