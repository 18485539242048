import { AppointmentCategoryVO, ProviderVO, RoomVO, WorkingHourItemVO } from "@libs/api/generated-api";

type PracticeInfo = {
  rooms: RoomVO[];
  providers: ProviderVO[];
  categories: AppointmentCategoryVO[];
  practiceHours: WorkingHourItemVO[];
  providerHoursByProvider: Record<number, WorkingHourItemVO[]>;
  selfBookingEnabled: boolean;
};

const simpleProvider = (provider: ProviderVO) => ({
  id: provider.id,
  name: provider.name.fullDisplayName,
  jobCategory: provider.jobCategory,
});

export const collectPracticeProviderInfo = ({
  selfBookingEnabled,
  rooms,
  providers,
  categories,
  providerHoursByProvider,
}: PracticeInfo) => {
  const hasNoRooms = !rooms.length;
  const activeProvidersWhoLoseHours: ProviderVO[] = [];
  const pendingProvidersWhoLoseHours: ProviderVO[] = [];
  const activeProvidersWhoLoseHoursAndCategoryAssignment: ProviderVO[] = [];
  const pendingProvidersWhoLoseHoursAndCategoryAssignment: ProviderVO[] = [];
  const providersRemovedFromOnlineBooking: ProviderVO[] = [];

  const allRoomsAssigned = rooms.every((room) => Boolean(room.providers?.length));

  if (!allRoomsAssigned) {
    return {
      providersRemovedFromOnlineBooking: providersRemovedFromOnlineBooking.map(simpleProvider),
      pendingProvidersWhoLoseHours: pendingProvidersWhoLoseHours.map(simpleProvider),
      activeProvidersWhoLoseHours: activeProvidersWhoLoseHours.map(simpleProvider),
      activeProvidersWhoLoseHoursAndCategoryAssignment:
        activeProvidersWhoLoseHoursAndCategoryAssignment.map(simpleProvider),
      pendingProvidersWhoLoseHoursAndCategoryAssignment:
        pendingProvidersWhoLoseHoursAndCategoryAssignment.map(simpleProvider),
    };
  }

  const providersWithNoRooms = providers.filter((provider) =>
    rooms.every((room) => !room.providers?.find((p) => p.id === provider.id))
  );

  for (const provider of providersWithNoRooms) {
    const hasProviderHours = Boolean(providerHoursByProvider[provider.id]?.length);
    const hasAssignedCategories = categories.some((category) =>
      category.providers.some((p) => p.id === provider.id)
    );

    if (
      !hasNoRooms &&
      provider.status === "ACTIVE" &&
      selfBookingEnabled &&
      providerHoursByProvider[provider.id]?.some((hour) => hour.isSelfBookable) &&
      categories.some(
        (category) =>
          category.isSelfBookable &&
          (!category.providers.length || category.providers.some((p) => p.id === provider.id))
      )
    ) {
      providersRemovedFromOnlineBooking.push(provider);
    } else if (hasProviderHours && hasAssignedCategories) {
      if (provider.status === "PENDING") {
        pendingProvidersWhoLoseHoursAndCategoryAssignment.push(provider);
      } else {
        activeProvidersWhoLoseHoursAndCategoryAssignment.push(provider);
      }
    } else if (hasProviderHours) {
      if (provider.status === "PENDING") {
        pendingProvidersWhoLoseHours.push(provider);
      } else {
        activeProvidersWhoLoseHours.push(provider);
      }
    }
  }

  return {
    providersRemovedFromOnlineBooking: providersRemovedFromOnlineBooking.map(simpleProvider),
    pendingProvidersWhoLoseHours: pendingProvidersWhoLoseHours.map(simpleProvider),
    activeProvidersWhoLoseHours: activeProvidersWhoLoseHours.map(simpleProvider),
    activeProvidersWhoLoseHoursAndCategoryAssignment:
      activeProvidersWhoLoseHoursAndCategoryAssignment.map(simpleProvider),
    pendingProvidersWhoLoseHoursAndCategoryAssignment:
      pendingProvidersWhoLoseHoursAndCategoryAssignment.map(simpleProvider),
  };
};
