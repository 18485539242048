import { FC, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as SignOutIcon } from "@libs/assets/icons/sign-out.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { Avatar } from "@libs/components/UI/Avatar";
import { paths } from "utils/routing/paths";

interface Props {
  name: string;
}

export const AdminProfileMenu: FC<Props> = ({ name }) => {
  const navigate = useNavigate();
  const profileMenu = useBoolean(false);
  const profileMenuOff = profileMenu.off;

  const menuOptions = useMemo(
    () =>
      [
        createMenuOption({
          label: "Sign Out",
          value: "sign-out",
          SvgIcon: SignOutIcon,
        }),
      ].filter(isDefined),
    []
  );

  const handleOptionClick = useCallback(() => {
    navigate(paths.signOut());

    profileMenuOff();
  }, [profileMenuOff, navigate]);

  return (
    <ButtonMenu
      className="text-greyDark flex items-center"
      isOpen={profileMenu.isOn}
      onRequestClose={profileMenu.off}
      onRequestOpen={profileMenu.on}
      menuContent={<MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />}
      floatingOffset={20}
      placement="right-end"
      shadow="heavy"
    >
      {(props) => (
        <button
          {...props}
          // Devops looks for this id and data attribute as a canary for
          // successful loading of the app
          id="logged-in-employee"
          data-employee-name={name}
          type="button"
        >
          <Avatar borderColor="slate-300" borderThickness="thick" name={name} />
        </button>
      )}
    </ButtonMenu>
  );
};
